import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Div100vh, { use100vh } from 'react-div-100vh'
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, snapshotEqual, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { addMessage, addToMessages, getMessagesByChatId, markChatRead, deleteChat } from '../../actions/chatActions';
import { setPage } from '../../actions/navActions';

// SocketIO connection file
import { socket } from '../../socket';

// Icons
import EventNoteIcon from '@material-ui/icons/EventNote';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import Messages from './Messages/Messages';
import Input from './Input';
import { Fragment } from 'react';
import DefaultAvatar from 'react-avatar';
import Spinner from '../common/Spinner';
import Verification_Badge from '../common/Verification_Badge';

const ChatBox = ({ 
    nav: {
        campus_name
    },
    addMessage, 
    getMessagesByChatId, 
    addToMessages, 
    markChatRead, 
    deleteChat, 
    chat, 
    auth, 
    name, 
    room, 
    match, 
    badgeValue,
    history,
    setPage
}) => {

    // Initialize the state for the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Initialize the state for the message input and chat messages
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [gotChatData, setGotChatData] = useState(false)
    const [chatData, setChatData] = useState(null) // For the purpose of displaying message read reciept

    // Flag to indicate if messages are loaded
    const [loadedMessages, setLoadedMessages] = useState(false);

    // States for file uploads
    const [files, setFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState(null);

    // States for storing other user's information in the chat
    const [otherChatUserId, setOtherChatUserId] = useState(null); // for the purpose of creating a Notification
    const [chatAvatar, setChatAvatar] = useState(null);
    const [gotChatName, setGotChatName] = useState(false);
    const [chatUser, setChatUser] = useState(null);
    const [chatName, setChatName] = useState(null);
    const [chatUserName, setChatUserName] = useState(null);
    const [chatJoinedDate, setChatJoinedDate] = useState(null);
    const [chatBio, setChatBio] = useState(null);
    const [sentReadStatus, setSentReadStatus] = useState(false);

    // Toggle Dropdown state
    const [dropdown, setDropdown] = useState(false);
    
    // Menu Height state
    const [menuHeight, setMenuHeight] = useState(null);

    // Edit Modal toggles
    const [deleteModal, setDeleteModal] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [noMoreMessages, setNoMoreMessagess] = useState(false);
    const [showTopSpinner, setShowTopSpinner] = useState(false);
    const [loadMoreDelay, setLoadMoreDelay] = useState(true);

    // Firebase collection reference for messages
    const messagesCollectionRef = collection(db, "messages");

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000; 

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set the current page to 'messages'
        setPage('chat');

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        // Set a delay to give the page time to scroll to bottom on initial page load
        // this prevents the loadMore function from being called unintentionally
        if(loadMoreDelay) {
            setTimeout(() => {
                
                setLoadMoreDelay(false);
            }, 3000 );
        }

    }, [loadMoreDelay]);

    useEffect(() => {

        // Set the current page for purposes of displaying bottom nav
        if(isMobile) {
            setPage('chat');
        } else {
            setPage('messages');
        }
    }, [isMobile]);

    // useEffect(() => {
    //     if(chat.chat) {
    //         markChatRead(chat.chat._id);
    //     }
    // }, [chat.chat]);

    
    // Fetch chat messages from Firestore
    useEffect(
        () => 
            onSnapshot(query(messagesCollectionRef, where("chat", "==", room), orderBy('createdAt', 'desc'), limit(15)), (snapshot) => {
                console.log('UPDATING CHAT MESSAGES...');
                
                // sortByDate(snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id})));
                setMessages(snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id})))

                // Get the last visible document for infinite scroll
                setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
            }
        ), [])

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    const sortByDate = tempMessageList => {
        let newArray = [];

        for (let i = 0; i < tempMessageList.length; i++) {
            let pushIndex = tempMessageList.length - 1;
            newArray.push(tempMessageList[pushIndex - i])
        }

        // Update the messages state with the new messages
        setMessages(newArray);
     };

    // Function to navigate back based on the previous URL
    const goBack = () => {

        let previousURL = document.referrer;

        var pathArray = previousURL.split( '/' );
        var host = pathArray[2];

        console.log(pathArray);

        window.location.href = '/messages';

        // Check if from this source or from chat box page
        // if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("oubuysell") || host.toLowerCase().includes("localhost:3000"))) {
        //     if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
        //         window.location.href = '/home';
        //     } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
        //         window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
        //     } else {
        //         history.goBack();
        //     }
        // } else {
        //     window.location.href = '/home';
        // }
    }

    // Function to send a message
    const sendMessage = (e, widthHeights) => {
        e.preventDefault();

        if(JSON.parse(chat.editorText)?.blocks[0].text || chat.editorImgFiles.length > 0) {
            addMessage(campus_name, { text: chat.editorText }, chat.editorImgFiles[0], widthHeights, room, auth.user, otherChatUserId);

            setMessage('');

            console.log('SUCCESS SENDING');
        }
    }

    // console.log('MESSAGE INFO');
    // console.log(message, messages);

    // Toggle the delete modal
    const toggleDeleteModal = () => {
        setDropdown(false)
        setDeleteModal(!deleteModal);
    }

    // Handle chat deletion
    const handleDelete = () => {
        if(chat.chat) {
            deleteChat(room, history);
        }

        setDeleteModal(false);
    }

    const fetchData = async () => {
        try {
            // Create a reference to the chat document using the provided id
            const docRef = doc(db, 'chats', chat.chat._id);
    
            // Retrieve the chat document using the docRef
            const chatDoc = await getDoc(docRef);

            setChatData(chatDoc.data());
        } catch (err) {
            console.log('Error fetching chat data:', err);
        }
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = scrollTop;

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        console.log('NEW SCROLL Y')
        console.log(newScrollY)
        console.log('scrollHeight: ', scrollHeight)
        console.log('scrollTop: ', scrollTop)
        console.log( 'offsetHeight: ', offsetHeight)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY <= 67 && !showTopSpinner && !noMoreMessages && !loadMoreDelay && messages.length > 0) {
            console.log('scrolling');
            setLoadMoreDelay(true);
            loadMore();
        }
    }

    // Function to load more paginated posts
    const loadMore = () => {
        console.log("last", lastPageDoc);

        // Show spinner
        setShowTopSpinner(true);
        
        // Construct a new query starting at the last visible document and fetch the next 3 posts.
        const nextQuery = query(messagesCollectionRef, where("chat", "==", room), orderBy('createdAt', 'desc'), startAfter(lastPageDoc || 0), limit(15));

        // Fetch the next set of posts
        onSnapshot(nextQuery, async (snapshot) => {
            // If there are no more documents in the database to load
            if(snapshot.docs.length < 2) {
                setNoMoreMessagess(true);
                console.log('LOAD MORE DOCS HERE')
                console.log(snapshot.docs);
            } else {

                console.log('UPDATING MESSAGES...');
                
                const tempMessageList = [...messages, ...snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}))];

                // sortByDate(tempMessageList);

                // Update the posts state with the new posts
                setMessages(tempMessageList);
    
                // Get the last visible document for the next load
                setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
    
                // Hide the loading spinner
                setTimeout(() => {
            
                    setShowTopSpinner(false);
                }, 2000 );
            }

        })
    }
    // END - Infinite Scroll

    // Mark the chat as read if it exists and hasn't been marked as read
    if(chat.chat && !sentReadStatus) {
        markChatRead(room);
        setSentReadStatus(true);
    }

    if(chat.chat && !gotChatData) {

        // Call the async function to fetch the data
        fetchData();

        setGotChatData(true);
    }

    // Check if the authenticated user and chat information exist and the chat name has not been retrieved yet
    if(auth.user && chat.chat && chat.chat.to_user && chat.chat.from_user && !gotChatName) {

        // Check if the authenticated user is the recipient of the chat (to_user)
        if(auth.user._id === chat.chat.to_user._id) {

            // Set the other member of the chats ID for the purpose of a notification
            setOtherChatUserId(chat.chat.from_user._id);

            // Set the chat avatar as the other user's image
            setChatAvatar(chat.chat.from_user.img);

            // Set the chat user as the other user's user object
            setChatUser(chat.chat.from_user);
            // Set the chat name as the other user's full name
            setChatName(chat.chat.from_user.first_name + ' ' + chat.chat.from_user.last_name);

            // Set the chat username as the other user's username
            setChatUserName(chat.chat.from_user.username);

            // Set the date the other user joined the group
            setChatJoinedDate(chat.chat.from_user.date);

            // Set the chat bio as the other user's bio if available
            if(chat.chat.from_user.bio) setChatBio(chat.chat.from_user.bio)
            
            // Mark that the chat name has been retrieved
            setGotChatName(true);
        } 
        // Check if the authenticated user is the sender of the chat (from_user)
        else if (auth.user._id === chat.chat.from_user._id) {

            // Set the other member of the chats ID for the purpose of a notification
            setOtherChatUserId(chat.chat.to_user._id);

            // Set the chat avatar as the other user's image
            setChatAvatar(chat.chat.to_user.img);

            // Set the chat user as the other user's user object
            setChatUser(chat.chat.to_user);

            // Set the chat name as the other user's full name
            setChatName(chat.chat.to_user.first_name + ' ' + chat.chat.to_user.last_name);
            
            // Set the chat username as the other user's username
            setChatUserName(chat.chat.to_user.username);

            // Set the date the other user joined the group
            setChatJoinedDate(chat.chat.to_user.date);
            
            // Set the chat bio as the other user's bio if available
            if(chat.chat.to_user.bio) setChatBio(chat.chat.to_user.bio)
            
            // Mark that the chat name has been retrieved
            setGotChatName(true);
        }
    }

    // Calculate the page height using the use100vh() custom hook
    const pageHeight = use100vh();

    // Calculate the body height by subtracting the height of the header (100 pixels) from the page height
    // If the page height is not available, set the body height to '87vh'
    const bodyHeight = pageHeight ? pageHeight - 76 : '87vh';

    return (
        <Fragment>
            <Div100vh>
                <div className="chatBox">

                    {/* ChatBox Header */}
                    <div className="chatBox__header">

                        {/* Render back button for tablets */}
                        {isTablet && (
                            <div onClick={goBack} className="chatBox__btnContainer">
                                <ArrowBackIcon />

                                {/* Render badge value if mobile */}
                                {isMobile && badgeValue > 0 ? (
                                    <span className="feed-header-badge defaultBadge">
                                        {badgeValue}
                                    </span>
                                ) : null}
                            </div>
                        )}

                        {/* ChatBox Avatar */}
                        <div onClick={() => window.location.href = `/${chatUserName}`} className="chatBox__avatar">

                            {chatAvatar ? (
                                <Avatar src={chatAvatar && chatAvatar} />
                            ) : (
                                <DefaultAvatar name={chatName && chatName} />
                            )}
                        </div>

                        {/* ChatBox Header Text */}
                        <div className="chatBox__headerText">

                            {/* ChatBox User Name */}
                            <h3>
                                {chatName && chatName} {' '}

                                {/* Render verified user badge */}
                                <span className="chatBox__headerSpecial">
                                    <Verification_Badge user={chatUser} />
                                </span>
                            </h3>
                            <p>@{chatUserName && chatUserName}</p>
                        </div>

                        {/* Dropdown menu button */}
                        <div onClick={() => setDropdown(!dropdown)} className="post__moreBtn chatMore">
                            <ErrorOutlineIcon />
                        </div>

                        {/* Dropdown menu */}
                        <div className={dropdown ? "edit-dropdown inboxHeader active" : "edit-dropdown inboxHeader"} style={{height: menuHeight}}>
                            <div className="menu">

                                {/* Delete conversation option */}
                                <div onClick={toggleDeleteModal} className="menu-item danger">
                                    <DeleteIcon />
                                    Delete Conversation
                                </div>
                            </div>
                        </div>

                        {/* Overlay for dropdown menu */}
                        <div
                            className={`hidden-overlay ${dropdown ? "show" : ""}`}
                            onClick={() => setDropdown(!dropdown)}
                        />
                    </div>

                    {/* ChatBox Body */}
                    <div className="chatBox__body" style={{ height: bodyHeight }}>
                        <div onScroll={handleScroll} className="chatBox__chat">

                            {/* Display from_user's profile info */}
                            {/* <div onClick={() => window.location.href = `/${chatUserName}`} className="chatBox__profile">
                                <div className="profile__profileHeader">

            
                                    <h3>
                                        {chatName && chatName}

                                        <span className="chatBox__profileSpecial">
                                            <VerifiedUserIcon className="chatBox__badge active"></VerifiedUserIcon>
                                        </span>
                                    </h3>
                                </div>
                                <div className="profile__profileHeader">

                  
                                    <h3>
                                        <span className="chatBox__profileSpecial">
                                            @{chatUserName}
                                        </span>
                                    </h3>
                                </div>
                                <div className="profile__description">

                     
                                    <p>{chatBio && chatBio.slice(0, 160)}</p>
                                </div>


                                <div className="profile__membership">
                                    <EventNoteIcon />
                                    {chatJoinedDate && <p>Joined {format(chatJoinedDate, "MMMM yyyy")} </p>}
                                </div>
                            </div> */}
                            
                            {/* Chat messages */}
                            <div className="chatBox__messages">

                                {/* Render a spinner at the bottom if there are more posts to load */}
                                {showTopSpinner && !noMoreMessages && <Spinner />}
                                
                                {/* Display a message when there are no more posts */}
                                {/* {(noMoreMessages && messages.length !== 0) && (<div className="no-rides"> "You've reached the end."</div> )} */}

                                <Messages loadMoreDelay={loadMoreDelay} showTopSpinner={showTopSpinner} chatData={chatData} messages={messages} name={name} chatAvatar={chatAvatar} otherChatUserId={otherChatUserId} />
                            </div>
                        </div>
                        <div className="chatBox__footer">

                            {/* Input for sending messages */}
                            <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
                        </div>
                    </div>

                </div>
            </Div100vh>

            {/* Delete conversation confirmation modal */}
            <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                <Modal>

                    {/* Modal Header */}
                    <div>
                        <h2>Delete conversation?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This conversation will be deleted from both your inbox and the other person's inbox. Once you do this it can't be undone. </p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDelete}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Delete
                    </Button>
                    <Button 
                        onClick={toggleDeleteModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}
ChatBox.propTypes = {
    nav: PropTypes.object.isRequired,
    // Prop type validation for function to send a meesage
    addMessage: PropTypes.func.isRequired,
    // Prop type validation for function to get a chat's messages by chat ID
    getMessagesByChatId: PropTypes.func.isRequired,
    // Not in use
    addToMessages: PropTypes.func.isRequired,
    // Prop type validation for function to mark a chat as read
    markChatRead: PropTypes.func.isRequired,
    // Prop type validation for function to delete a chat
    deleteChat: PropTypes.func.isRequired,
    // Prop type validation for function to set the current page
    setPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    addMessage, 
    getMessagesByChatId, 
    addToMessages, 
    markChatRead, 
    deleteChat,
    setPage 
})(ChatBox);
