import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

//  Actions - imported
import { editUserName, editUserBio } from '../actions/authActions';
import { setPage, toggleAuthModal, add_To_Recent_History, toggleSideNav } from '../actions/navActions';
import { getUserByUsername, getUserById } from '../actions/authActions';
import { getPostsByUserId, getUserPhotos, setPosts, getLikedPosts, getUserReplies, set_ShowBottomSpinner } from '../actions/postActions';
import { createChat } from '../actions/chatActions';

// wrapper layout
import Layout from '../components/layout/Layout';

// Modal
import Modal from '../components/modal/Modal';
import ModalContainer from '../components/modal/ModalContainer';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import ProfileHeader from '../components/Profile/ProfileHeader';
import Post from '../components/Post/Post';
import Image_Block from './MediaPage/Image_Block';
import Empty_Card from './MediaPage/Empty_Card';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';

// Edit profile - page
import ProfileEdit from '../components/Profile/ProfileEdit';

const initialState = {
    first_name: '',
    last_name: '',
    bio: ''
};

const Profile = ({ 
    setPage,
    toggleAuthModal,
    add_To_Recent_History,
    toggleSideNav,
    auth: { 
        user, 
        modalUser, 
        loading 
    }, 
    post,
    nav: {
        sideNav,
        page,
        campus_id
    },
    getPostsByUserId, 
    getUserPhotos,
    getLikedPosts,
    getUserReplies,
    set_ShowBottomSpinner,
    setPosts,
    getUserById,
    createChat,
    getUserByUsername, 
    editUserName, 
    editUserBio,
    match,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Load correct user 
    const [gotModalUser, setGotModalUser] = useState(false);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);

    // Posts
    const [postList, setPostList] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Get Post Total 
    const [gotPostTotal, setGotPostTotal] = useState(false);
    const [totalPosts, setTotalPosts] = useState(0);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);
    

    // Modal toggles
    const [messageModal, setMessageModal] = useState(false);

    // Get the "show" and "redirect" parameters from the URL
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("show");
    const showMessageModal = url.searchParams.get("redirect");

    const SPACE_HEIGHT = 250; // Hieght of className 'no-rides' which is a spacer to make sure user can always scroll down

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        if(modalUser) {

            // Redirect to the user's profile if showMessageModal is true
            if(showMessageModal === 'true') {
                toggleMessageModal();

                history.push(`/${modalUser.username}`)
            }
        }

    }, [modalUser])

    useEffect(() => {

        // Set the current page to 'profile'
        setPage('profile');
        add_To_Recent_History(url.pathname);  // current url

        getUserByUsername('tundeadepitan');

        // Get the user by username from the route parameter
        // if(match.params.username) {
        //     if(match.params.username !== modalUser?.username) { // This conditional prevents the program from calling getUserByUsername() twice
        //         getUserByUsername(match.params.username);
        //     }
        // } else {
        //     if(!loading && user) {
        //         getUserByUsername(user.username);
        //     }
        // }
    }, [match.params.username, user])

    useEffect(() => {
        setGotPosts(false);
        console.log('CALLING FETCH USERS')
        fetchUserPosts();

    }, [modalUser, filter]);

    useEffect(() => {

        renderPostList();

    }, [post.posts, canLoadMore]);

    // --- Profile TABS logic ---

    const fetchUserPosts = () => {

        if(modalUser) {
            if(filter) {

                // Load posts with photos if on the profile's 'photos' tab
                if(filter === 'photos') {

                    getUserPhotos(modalUser._id, campus_id)
                    setGotPosts(true);
                } else if(filter === 'likes') {

                    getLikedPosts(modalUser._id, campus_id)
                    setGotPosts(true);
                } else if(filter === 'replies') {

                    getUserReplies(modalUser._id, campus_id);
                    setGotPosts(true);
                    
                }
                
                
            } else {

                getPostsByUserId(modalUser._id, campus_id);
                setGotPosts(true);
            }

            // Update the user's total posts
            if(modalUser.totalPosts) {
                setTotalPosts(modalUser.totalPosts);
            }
        }
    }

    // --- END: Profile TABS ---

    // Message Logic

    const toggleMessageModal = () => {
        
        setMessageModal(!messageModal);
    }

    const continueMessage = () => {
        if(user) {
            createNewChat();
        } else {
            toggleMessageModal();
            toggleAuthModal('message');
        }
    }

    // Create a new chat between users
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", user._id), where("to_user._id", "==", modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", user._id), where("from_user._id", "==", modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {
            createChat(modalUser, user, history);
        }

        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // End

    const toggleEdit = () => {
        // setEditMode(!editMode);
        window.location.href = "/profile/edit";
        // history.push('/profile/edit')
    } 

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - (SPACE_HEIGHT+1) && !post.show_bottom_spinner && !post.no_more_posts && post.posts.length > 0 && canLoadMore) {
            console.log('scrolling');
            loadMore(post.last_page_doc);
        }
    }

    const loadMore = (lastPage_doc) => {
        console.log("LOADING MORE");
        console.log("last", lastPage_doc);

        // Temporarily disable to load more function to avoid duplicate fetch requests
        setCanLoadMore(false);

        // Show spinner
        set_ShowBottomSpinner(true);

        if(filter) {

            // Load posts with photos if on the profile's 'photos' tab
            if(filter === 'photos') {

                getUserPhotos(modalUser._id, campus_id, lastPage_doc)

            } else if(filter === 'likes') {

                getLikedPosts(modalUser._id, campus_id, lastPage_doc)

            } else if(filter === 'replies') {

                getUserReplies(modalUser._id, campus_id, lastPage_doc);
                
            }
            
            
        } else {
            getPostsByUserId(modalUser._id, campus_id, lastPage_doc)
        }
        
        // Re-enable the load more function after 1 second
        setTimeout(() => {
            setCanLoadMore(true);
        }, 1000);
    }

    // Check if user is verified. If no redirect to verify page
    if(user && !user.verified && !checkedVerification) {
        window.location.href = '/verify';

        setCheckedVerification(true);
    }

    const renderPostList = async () => {
        setPostList([]);

        if((post.posts === null && loading) || !gotPosts) {
            setPostList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(post.posts.length > 0) {
                post.posts.map(post_doc => {
                    if (filter === 'photos') {
                        setPostList(postList => [...postList, (
                            <Image_Block key={post_doc._id} detailPost={post_doc} />
                        )])
                    } else {
                        const renderPostRecursive = (postComments, parentPost) => {
                        
                            const lastComment = postComments[postComments.length - 1]; // Get the last comment
        
                            return (
                                <Fragment>
                                    <Post key={lastComment._id} postDoc={lastComment} parentPost={parentPost} />
        
                                    {/* Recursive call to render comments of comments */}
                                    {lastComment.commentsOfComments && lastComment.commentsOfComments.length > 0 && (
                                        <div className="comments-of-comments">
                                        {renderPostRecursive(lastComment.commentsOfComments, lastComment)}
                                        </div>
                                    )}
                                </Fragment>
                            );
        
                        };
        
                        if(post_doc.commentsOfComments && post_doc.commentsOfComments.length > 0) {
                            setPostList(postList => [...postList, (
                                <div className='post_container'>
                                    <Post key={post_doc._id} postDoc={post_doc} />
        
                                    {renderPostRecursive(post_doc.commentsOfComments, post_doc)}
                                </div>
                            )])
                        } else {
                            setPostList(postList => [...postList, (
                                <Post key={post_doc._id} postDoc={post_doc} />
                            )])
                        }
                    }
                }) 
            } else {
                setPostList(postList => [...postList, (
                    <div className="no-rides">
                        <h1>No Posts</h1>
                    </div>
                )])
                
            }
        } 

        setGotPosts(true);
    }

    // Initialize Mixpanel and track Page View
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Profile View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(user && user._id === 'pjaWd3NhsqNtC5fw2PRROQdf28F2') {
        isMyProfile = true;
    } else {
        isMyProfile = false;
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="profile" user={modalUser} totalPosts={totalPosts}>

                {!modalUser ? (
                    <div className="no-post">
                        <h1>This page doesn’t exist. </h1>
                        <p>Try searching for something else.</p>
                        <Button  
                            onClick={() => window.location ='/search'}
                            className="noChats__btn"
                        >
                            Search
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        {/* Render the profile header */}
                        <ProfileHeader toggleMessageModal={toggleMessageModal} toggleEdit={toggleEdit} />
                        
                        {/* Render the post list */}
                        {(filter === 'photos' && post.posts.length > 0) ? (
                            <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                                
                                {postList}

                                {(!post.no_more_posts && post.posts.length !== 0) && (<Empty_Card last_page_doc={post.last_page_doc} page={"gallery"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}
                            </div>
                        ) : postList}

                        {/* Show a spinner at the bottom if more posts are being loaded */}
                        {post.show_bottom_spinner && !post.no_more_posts && <Spinner />}
                        
                        {/* Render a message when there are no more posts to load */}
                        <div className="no-rides">{(post.no_more_posts && post.posts.length !== 0) && "You've reached the end."}</div>
                        
                        {/* Render a button to create a new post for tablet devices */}
                        {!loading && user && isMyProfile ? (
                            isMobile && (
                                <Link to={`/create/post?goBack=${page}`} id="fixed-button" className="show">
                                    <AddIcon />
                                </Link>
                            )
                        ) : null}
                    </Fragment>
                )}

            </Layout>

            {/* Start a Conversation modal */}
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Text */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{modalUser ? `${modalUser.first_name}${modalUser.last_name && ' ' + modalUser.last_name}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={continueMessage}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleMessageModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

Profile.propTypes = {
    setPage: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    getUserByUsername: PropTypes.func.isRequired,
    getPostsByUserId: PropTypes.func.isRequired,
    getUserPhotos: PropTypes.func.isRequired,
    getUserReplies: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    getLikedPosts: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
    editUserName: PropTypes.func.isRequired, 
    editUserBio: PropTypes.func.isRequired, 
    auth: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    post: state.post,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage,
    toggleAuthModal,
    add_To_Recent_History,
    toggleSideNav,
    getUserByUsername,
    getPostsByUserId,
    getUserPhotos,
    getLikedPosts,
    getUserReplies,
    set_ShowBottomSpinner,
    setPosts,
    getUserById,
    createChat,
    editUserName, 
    editUserBio,
})(Profile);
