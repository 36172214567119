import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { addSearchQuery, getRecentSearches, deleteRecentSearch, clearRecentSearch } from '../../actions/searchActions';

// Components
import Spinner from './Spinner';
import { Button } from '@material-ui/core';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// Icons - material UI
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';


const Search_Input = ({
    auth,
    search: {
        recent_searches,
        recent_loading,
        popular_searches
    },
    nav: {
        campus_name
    },
    addSearchQuery, 
    getRecentSearches, 
    deleteRecentSearch, 
    clearRecentSearch
}) => {

    // --- Get Current URL and extract URL variables --- 

    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("q"); // extract the search keyword from "q" parameter
    
    // --- END: Get Current URL and extract URL variables --- 


    // --- Algolia Stuff ---
    const searchClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9'); // change the secret key (2nd) to specific api key
    const searchRecommendationClient = algoliasearch('H8YQ3RB3ZI', '8ad74b3884c12ec628eb17e0e9f49935');

    const searchIndex = searchClient.initIndex('posts_date_desc');
    const recommendationIndex = searchRecommendationClient.initIndex('searches');

     // --- END: Algolia Stuff ---

    // ---- States for Search stuff

    const [inputClicked, setInputClicked] = useState(false);

    const [searchText, setSearchText] = useState('');

    const [searchRecommendations, setSearchRecommendations] = useState([]);

    const [dropdownOptionIndex, setDropdownOptionIndex] = useState(-1);

    const [searchDropdownArray, setSearchDropdownArray] = useState([]);

    // Delete Recent Searches Modal toggle
    const [deleteRecentSearchModal, setDeleteRecentSearchModal] = useState(false);

    // ---- END States for Search stuff

    // --- Page Listeners ---

    useEffect(() => {

        if(filter === null) return;
        setSearchText(filter);
        handleRecommendations(filter);

        if(!auth.loading) addSearchQuery(filter, auth.user); // If already checked authentication

    }, [filter, auth.loading])  

    useEffect(() => {

        if(auth.user) {
            // Get recent searches
            getRecentSearches(auth.user._id);
        }

    }, [auth.user]);

    // Update the array for counting the search dropdown index for when up/down arrows are clicked
    useEffect(() => {

        if(searchText.length !== 0) {
            if(searchDropdownArray.length === 0 && recent_searches.length > 0) {
                setSearchDropdownArray([...recent_searches])
            } else {
                setSearchDropdownArray([...searchRecommendations, ...recent_searches])
            }
        }

    }, [searchRecommendations, recent_searches]);

    useEffect(() => {

        if(!inputClicked) {
            setDropdownOptionIndex(-1);
        }

    }, [inputClicked]);

    useEffect(() => {
        // 👇 Get the Input element by Id
        const searchInput = document.getElementById('search-input');
        
        if (!searchInput) return;


        searchInput.addEventListener("focusin", (event) => {
            console.log('FOCUS IN')
            setInputClicked(true);
        });
        
        // searchInput.addEventListener("focusout", (event) => {
        //     console.log('FOCUS OUT')
        //     setInputClicked(false);
        // });

        return () => {
            searchInput.removeEventListener("focusin", (event) => {
                console.log('FOCUS IN')
                setInputClicked(true);
            });
        
            // searchInput.removeEventListener("focusout", (event) => {
            //     console.log('FOCUS OUT')
            //     setInputClicked(false);
            // });
        };

    }, []);

    useEffect(() => {

        if(searchText.length === 0) {
            setSearchRecommendations([]);

            if(filter) {
                if(popular_searches.length > 4) {
                    setSearchDropdownArray([...popular_searches.slice(0,4), ...recent_searches]);
                } else {
                    setSearchDropdownArray([...popular_searches, ...recent_searches]);
                }
            } else {
                setSearchDropdownArray([...recent_searches]);
            }
        }

    }, [searchText, recent_searches])

    // --- END: Page Listeners ---

    // Function to handle search input change
    const onChange = e => setSearchText(e.target.value);

    // --- Search Input Dropdown Logic ---

    const handleRecommendations = async (queryText) => {

        console.log('UPDATING RECOMMNEDATIONS');
        console.log(queryText)
        console.log(searchText)


        if(searchText.length - 1 > 0) {
            setDropdownOptionIndex(-1); // Reset search dropdown index

            try {

                if(queryText === '') return;

                const results = await recommendationIndex.search(queryText);

                console.log('SEARCH RESULT');
                console.log(results);

                setSearchRecommendations(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                setSearchDropdownArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID})), ...searchDropdownArray]);

            } catch (error) {
                console.log(error);
                setSearchRecommendations([]);
            }
        }
    }

    const onDownArrow = () => {

        console.log('SEARCH ARRAY')
        console.log(searchDropdownArray.length)

        console.log('SEARCH INDEX')
        console.log(dropdownOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchRecommendations.length)


        let nextIndex = dropdownOptionIndex + 1;

        if(nextIndex > searchDropdownArray.length - 1) {
            nextIndex = 0;
        }

        setDropdownOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = dropdownOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchDropdownArray.length - 1;
        }

        setDropdownOptionIndex(nextIndex);
    }

    // Toggle the delete recent searches confirmation modal
    const toggleDeleteRecentSearchModal = () => {

        // Toggles the delete modal
        setDeleteRecentSearchModal(!deleteRecentSearchModal); 
    }

    // Deletes all recent searches
    const handleDeleteRecentSearches = () => {

        if(auth.user) clearRecentSearch(auth.user._id)

        // Closes the modal
        setDeleteRecentSearchModal(false);
    }

    // --- END: Search Input Dropdown Logic ---

    // --- Submit & Handle Search --- 

    const handleSubmit = (queryText) => {
        if(dropdownOptionIndex > -1) {
            window.location.href = `/search?q=${searchDropdownArray[dropdownOptionIndex].text ? searchDropdownArray[dropdownOptionIndex].text : searchDropdownArray[dropdownOptionIndex].search_text}`;

            console.log('SEARCH THIS WORD')
            console.log(searchDropdownArray[dropdownOptionIndex]);
        } else {
            if(queryText === '') return;

            window.location.href = `/search?q=${queryText}`;
        }
    }

    // --- END: Submit & Handle Search --- 

    // --- Handle Rendering of Dynamic Lists ---

    let recommendations_list = [];

    if(searchRecommendations.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchRecommendations.length; i++) {
            const recommendation = searchRecommendations[i];
            
            if (recommendation) {
                recommendations_list.push(
                <div key={recommendation._id} onClick={() => window.location = `/search?q=${recommendation.text}`} className={dropdownOptionIndex === i ? "search-dropdown-item active" : "search-dropdown-item"}>
                    <div className='search_dropdown_item_icon'>
                    <SearchIcon />
                    </div>
                    <div className='search_dropdown_item_option'>
                    <div style={{fontWeight:700}}>
                        {recommendation.text}
                    </div>
                    </div>
                </div>
                );
            }
        }
    }

    let recent_list;

    if(auth.isAuthenticated && (recent_searches === null || recent_loading)) {
        recent_list = <Spinner />;
    } else if(recent_searches.length > 0) {

        recent_list = [];

        // Iterate through the recent_searches array and render each search query component
        for (let i = 0; i < recent_searches.length; i++) {
            const recent_search = recent_searches[i];

            let index_comparison;

            if(searchText.length > 0 || !filter) {
                index_comparison = searchRecommendations.length + i;
            } else {
                index_comparison = i;
            }

            if(recent_search) {
                recent_list.push(
                    <div key={recent_search._id} className={dropdownOptionIndex === index_comparison ? "search-dropdown-item active" : "search-dropdown-item"}>
                        <div className='search_dropdown_item_icon'>
                            <SearchIcon />
                        </div>
                        <div className='search_dropdown_item_option'>
                            <div>
                                {recent_search.search_text}
                            </div>
                            <div className='search__actionBtn' onClick={() => deleteRecentSearch(auth.user._id, recent_search._id)}>
                                <CloseIcon />
                            </div>
                        </div>

                        {/* Post Overlay to detect when clicked and redirect to postPage */}
                        <div 
                            className="post__overlay" 
                            onClick={() => window.location = `/search?q=${recent_search.search_text}`}
                        ></div>
                    </div>
                )
            }
        }
        
    } else {
        recent_list = null;
    }

    let popularSearch_list = [];

    for (let i = 0; i < popular_searches.length; i++) {
        const popular_search = popular_searches[i];
        
        if (popular_search) {
            popularSearch_list.push(
                <div onClick={() => window.location = `/search?q=${popular_search.text}`} className={dropdownOptionIndex === i ? "search-dropdown-item active" : "search-dropdown-item"} key={popular_search._id}>
                    <div className='search_dropdown_item_icon'>
                        <SearchIcon />
                    </div>
                    <div className='search_dropdown_item_option'>
                        <div className='generated_result'>
                            <span>
                                
                            </span>
                            <span>
                                {popular_search.text}
                            </span>
                        </div>
                        <div className='search_stats'>
                            {popular_search.total_views}
                            <SignalCellularAltOutlinedIcon />
                        </div>
                    </div>
                </div>
            );
        }
    }

    // --- END: Handle Rendering of Dynamic Lists ---

    return (
        <Fragment>
            <div className={`widgets__input shop ${inputClicked ? 'active' : ''}`}>
                <SearchIcon className="widgets__searchIcon" />
                <input 
                    id="search-input" 
                    placeholder={`Search here...`}
                    type="text" 
                    autocomplete="off" 
                    value={searchText}
                    onChange={e => onChange(e)}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter') {
                            handleSubmit(searchText);
                        } else if(e.keyCode == '40') { // down arrow
                            onDownArrow();
                        } else if(e.keyCode == '38') { // up arrow
                            onUpArrow();
                        } else {
                            handleRecommendations(searchText);
                        }
                    }}
                />
            </div>

            {/* Dropdown Menu */}
            <div className={inputClicked ? "search-dropdown active" : "search-dropdown"}>
                
                <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />

                {recommendations_list}

                {!recommendations_list?.length > 0 ? (
                    <Fragment>
                        {(filter && popular_searches.length > 0) && (
                            <Fragment>
                                <div className='menu-title' style={{display: 'flex', cursor:'default', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                                    <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                        <span>Popular searches</span>
                                    </div>
                                </div>

                                {popularSearch_list.slice(0, 4)}

                            </Fragment>
                        )}
                    </Fragment>
                ) : null}

                {recent_list && (
                    <Fragment>
                        <div className='menu-title' style={{display: 'flex', cursor:'default', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                            <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                <span>My Recent</span>
                            </div>

                            <div onClick={toggleDeleteRecentSearchModal} style={{minWidth: '24px', cursor:'pointer', minHeight:'24px', padding:'0 12px', color:'rgb(29, 155, 240)', display:'flex', justifyContent:'center', fontWeight:700, fontSize:'14px', lineHeight:'16px', wordBreak:'break-word', textAlign:'center', alignItems:'center'}}>
                                <span>Clear all</span>
                            </div>
                        </div>

                        {recent_list}
                    </Fragment>
                )}

                {(!filter && !recent_list?.length > 0 && !recommendations_list?.length > 0) && 

                    <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching for keywords</div>
                }
            </div>

            {/* Hidden Overlay for dropdown */}
            <div
                className={`hidden-overlay ${inputClicked ? "show" : ""}`}
                onClick={() => setInputClicked(!inputClicked)}
            />
            

            {/* Delete Recent Searches Modal */}
            <ModalContainer show={deleteRecentSearchModal} onClose={toggleDeleteRecentSearchModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Clear all recent searches?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can’t be undone and you’ll remove all your recent searches. </p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDeleteRecentSearches}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Clear
                    </Button>
                    <Button 
                        onClick={toggleDeleteRecentSearchModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

Search_Input.propTypes = {
    // Prop type validation for search
    search: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    addSearchQuery: PropTypes.func.isRequired, 
    getRecentSearches: PropTypes.func.isRequired, 
    deleteRecentSearch: PropTypes.func.isRequired, 
    clearRecentSearch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the search state
    search: state.search,
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    addSearchQuery, 
    getRecentSearches, 
    deleteRecentSearch, 
    clearRecentSearch
})(Search_Input);