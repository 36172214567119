import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { login, loginWithEmailAndPassword } from '../actions/authActions';
import { setAlert } from '../actions/alertActions';

// Layout Wrapper
import ModalPage from '../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../components/common/Alert';

// Icons -imported
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import miitoken_logo from '../utils/imgs/ou_logo.png';



// Initial State
const initialState = {
    email: '',
    password: ''
}

const ComingSoon = ({
    auth: { 
        isAuthenticated, 
        user 
    }, 
    nav: {
        campus_name,
        campus_ext
    },
    loginWithEmailAndPassword,
    setAlert,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [formData, setFormData] = useState(initialState);

    // State to toggle login methods
    const [loginWithPassword, setLoginWithPassword] = useState(true);

    // State to toggle email code inputs
    const [showEmailCode, setShowEmailCode] = useState(false);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const authMethod = url.searchParams.get("method");

    // Reference for the timer interval
    const Ref = useRef(null);

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    // Handle the Authentication Method via URL parameter
    useEffect(() => {
        if(authMethod && authMethod === 'password') {
            setLoginWithPassword(true)
        } 

        if(authMethod && authMethod === 'noPassword') {
            setLoginWithPassword(false)
        } 
    }, [authMethod]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Destructure the email value from form data
    const { email, password } = formData;

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Function to validate email format
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    

    const handleEmailSignInLink = e => {
        e.preventDefault();
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else {

            // Redirect to login link page with the provided data
            window.location.href = `/login/sent?email=${email}`;
        }
    }


    // Function to handle Email Code verification & submission
    const onSubmitAuthCode = async e => {
        e.preventDefault();
        
        window.alert('Processing verification code...')
    }

    const handleLogin = (e) => {
        e.preventDefault();

        // Authenticate the user
        loginWithEmailAndPassword(email, password);
    };

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Login Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // - Timer ----

    const [timer, setTimer] = useState('00:00:00:00');

    const getTimeRemaining = (e) => {

        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total, days, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        
        let { total, days, hours, minutes, seconds } = getTimeRemaining(e);
                    
        if (total >= 0) {
            
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                (days > 9 ? days : '0' + days) + ':' +
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':' +
                (seconds > 9 ? seconds : '0' + seconds)
            );
        }
    }
 
    const clearTimer = (e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('00:00:00:00');
 
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = () => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setDate(deadline.getDate() + 1);
        deadline.setHours(8, 0, 0, 0);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {

        clearTimer(getDeadTime());

    }, []);

    const secondsUntilDateTime = (targetDateTime) => {
        const currentDate = new Date();
        const targetDate = new Date(targetDateTime);
        
        if (targetDate <= currentDate) {
            return 0; // The target date and time has already passed
        }
        
        const timeDifference = targetDate - currentDate;
        const secondsRemaining = timeDifference / 1000; // Convert milliseconds to seconds
        
        return Math.floor(secondsRemaining);
    }

    // - END: Timer ----

    return (

        <div className='register_container login' style={{background: '#ECE1D5', display:'flex', flexDirection: 'column', padding: '20px'}}>
            <div className='register_content' style={{flex: 1, textAlign: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '50px'}}>
                    <h3>Coming Soon!</h3>
                    {/* <div onClick={() => {window.location = '/settings/howitworks'}} className='transparent_btn'>
                        
                        <div style={{textAlign: 'center', flex: 1}}>
                            <div style={{color: '#000', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '16px'}}>
                                How it works
                            </div>
                        </div>

                        <div style={{fontSize: '24px', color: '#000', marginTop: '5px'}}>
                            <ChevronRightIcon />
                        </div>
                    </div> */}
                </div>
      

                {/* Message */}

                <div style={{textAlign: 'center', fontWeight: 700, fontSize: '24px', marginTop: '20px'}}>
                    Hi! sorry I'm not quite ready yet...
                </div>
                <div style={{textAlign: 'center', fontWeight: 700, fontSize: '48px', marginTop: '20px', color: '#FF6347'}}>
                    {/* Replace with actual countdown logic */}
                    {timer}
                </div>

                <div style={{textAlign: 'center', fontWeight: 700, fontSize: '18px', marginTop: '20px'}}>
                    What to expect:
                </div>
                <ul style={{textAlign: 'center', fontSize: '16px', marginTop: '10px', listStyleType: 'none', padding: '0'}}>
                    <li>🚀 My contact info</li>
                    <li>🛒 Stuff about me</li>
                    <li>🎁 My projects</li>
                    <li>🌟 Short blog posts</li>
                </ul>
                <div style={{textAlign: 'center', fontWeight: 700, fontSize: '18px', marginTop: '20px'}}>
                    Stay tuned for the big launch!
                </div>

                <div onClick={() => window.open("https://www.instagram.com/tunde.tv/", "_blank")} className='register_btns' style={{display: 'flex', marginTop: '20px', alignItems:'center', flexDirection:'column'}}>
                    <button 
                        style={{width: '100%', background: '#000', borderColor: '#000' ,color: '#fff',}}
                        className={`register_btn active`}
                    >
                        <img style={{height: '25px', width: '25px', marginRight: '5px'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png" />
                        My Instagram
                    </button>
                </div>
                
            </div>

            {/* Page footer - fixed to bottom of screen */}
    
            {/* <div style={{textAlign: 'center', width: '100%', fontSize: '14px', lineHeight: '23px'}}>
                <span>
                    By continuing you agree to our <br/>
                    <a style={{textDecoration: 'none'}} href='/settings/terms'>
                        Terms of Service
                    </a>
                    {' '}& {' '}
                    <a style={{textDecoration: 'none'}} href='/settings/privacy'>
                        Privacy policy
                    </a>
                </span>
            </div> */}

        </div>
    )
}

// Prop types for the Login component
ComingSoon.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    loginWithEmailAndPassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    loginWithEmailAndPassword,
    setAlert 
})(ComingSoon);
