import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History, toggleUserModal } from '../../actions/navActions';
import { getUserById, clearUser } from '../../actions/authActions';
import { createChat } from '../../actions/chatActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// components
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import Post from '../../components/Post/Post';
import { Button, Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// Modal
import ModalContainer from '../../components/modal/ModalContainer';
import Modal from '../../components/modal/Modal';

const CreateNewMessage = ({ 
    setPage, 
    add_To_Recent_History,
    auth: { 
        modalUser,
        user 
    }, 
    createChat,
    getUserById,
    clearUser,
    toggleUserModal, 
    nav: { userModal }, 
    history 
}) => {
    
    // State variable for tracking Mixpanel event
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Uploading state
    const [uploading, setUploading] = useState(false);

    const [messageModal, setMessageModal] = useState(false);

    // --- Search stuff

    const [searchText, setSearchText] = useState('');

    const [searchUserList, setSearchUserList] = useState([]);
    
    const [userListOptionIndex, setUserListOptionIndex] = useState(-1);

    const [searchUserListArray, setSearchUserListArray] = useState([]);

    // --- END Search stuff

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    const searchUsersClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9');
    const usersIndex = searchUsersClient.initIndex('users');

    useEffect(() => {

        // Event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set current page to 'create'
        setPage('create');
        add_To_Recent_History(url.pathname);  // current url

        // Clean up event listener
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        if(searchText.length === 0) {
            clearUser();

            setSearchUserList([]);
            
            setSearchUserListArray([]);
        } else {
            clearUser();
        }

    }, [searchText])

    useEffect(() => {

        if(modalUser && searchText.length > 0) {
            toggleMessageModal()
        }

    }, [modalUser])

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Check if the window size is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Redirect logic for non-mobile devices
    if(!isMobile) {
        history.push(`/messages`);
    }

    // Function to handle input change
    const onChange = e => setSearchText(e.target.value);

    const onDownArrow = () => {

        // TODO: Detect arrow keys when the input is not in focus, 
        // currently if user clicks the cancel button the arrows dont work unless the user clicks the input again

        console.log('SEARCH ARRAY')
        console.log(searchUserListArray.length)

        console.log('SEARCH INDEX')
        console.log(userListOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchUserList.length)


        let nextIndex = userListOptionIndex + 1;

        if(nextIndex > searchUserListArray.length - 1) {
            nextIndex = 0;
        }

        setUserListOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = userListOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchUserListArray.length - 1;
        }

        setUserListOptionIndex(nextIndex);
    }

    const handleUserClick = (hit) => {
        if(modalUser) {
            clearUser();
        } else {
            getUserById(hit.objectID);
        }
    }

    const toggleMessageModal = () => {

        if(modalUser) {
            setMessageModal(!messageModal);
        }
    }

    // User clicked the 'Cancel' button in the message modal
    const toggleTodo = () => {
        toggleMessageModal();
        clearUser();
    }

    const handleUserSearch = async (queryText) => {

        if(searchText.length - 1 > 0) {
            setUserListOptionIndex(-1); // Reset search dropdown index

            try {

                if(queryText === '') return;

                const results = await usersIndex.search(queryText);

                console.log('SEARCH RESULT');
                console.log(results);

                setSearchUserList(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                setSearchUserListArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID}))]);

            } catch (error) {
                console.log(error);
                setSearchUserList([]);
            }
        }
    }

    const handleSubmit = (queryText) => {
        if(userListOptionIndex > -1) {
            handleUserClick(searchUserListArray[userListOptionIndex])

            console.log('SEARCH THIS WORD')
            console.log(searchUserListArray[userListOptionIndex]);
        } else {
            if(queryText === '') return;

            window.alert('Please select a user!');
        }
    }

    // Mixpanel tracking logic
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Mobile New Message Page View");
    }

    // Trigger Mixpanel tracking on first render
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Handle chat creation
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", user._id), where("to_user._id", "==", modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", user._id), where("from_user._id", "==", modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            window.location = `/messages/${chats[0]._id}`;
        } else if (otherChats.length > 0) {
            window.location = `/messages/${otherChats[0]._id}`;
        } else {
            createChat(modalUser, user, history);
        }

        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    let user_list = [];

    if(searchUserList.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchUserList.length; i++) {
            const hit = searchUserList[i];
            
            if (hit) {
                user_list.push(
                    <div key={hit.objectID} onClick={() => handleUserClick(hit)} className={userListOptionIndex === i || (modalUser && (hit.objectID === modalUser._id)) ? "userTable__block active" : "userTable__block"}>
                        
                        {/* User Avatar - left */}
                        <div className="userTable__avatar">
                            {hit.img ? (
                                <Avatar src={hit.img} />
                            ) : (
                                <DefaultAvatar name={`${hit.first_name} ${hit.last_name}`} />
                            )}
                        </div>

                        {/* Display the user's name & username */}
                        <div className="userTable__info">
                            <h3>
                                {hit.first_name}{' '}{hit.last_name}
                                <span className="userTable__infoSpecial">
                                    <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                                </span>
                            </h3>
                            <p>@{hit.username}</p>
                        </div>

                        {/* Show if User Block has been clicked */}
                        <div className="userTable__active">

                            {/* Check if the current user is the same as the selected user */}
                            {modalUser && (hit.objectID === modalUser._id) && <CheckIcon />}
                        </div>
                    </div>
                );
            }
        }
    }

    return (
        <Fragment>
            <div className="newMessage__page">

                {/* Modal Header */}
                <div style={{display:'flex', justifyContent:'space-between'}}>

                    {/* Close Button */}
                    <button onClick={() => window.location = '/messages'} className="new_message_btnContainer close">
                        <ArrowBackIcon /> 
                    </button>

                    {/* Modal Title */}
                    <h2>New Message</h2>

                    {/* Submit Button */}
                    <div>
                        <Button 
                            onClick={toggleMessageModal} 
                            className={`tweetBox__tweetButton ${!modalUser ? 'disabled' : ''}`}
                            disabled={!modalUser}
                        >
                            Next
                        </Button>
                    </div>
                </div>

                {/* User Search Bar */}
                <div className="modal__search">
                    {/* <SearchIcon />
                    <SearchBox /> */}
                    <SearchIcon />
                    <input 
                        id="search-input" 
                        placeholder="Search people here..." 
                        type="text" 
                        autocomplete="off" 
                        value={searchText}
                        onChange={e => onChange(e)}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                handleSubmit(searchText);
                            } else if(e.keyCode == '40') { // down arrow
                                onDownArrow();
                            } else if(e.keyCode == '38') { // up arrow
                                onUpArrow();
                            } else {
                                handleUserSearch(searchText);
                            }
                        }}
                    />
                </div>
                
                {/* User Search Results */}
                <div className="modal-table-list-container modular createPage">
                    {/* <UserTable /> */}
                    {/* <Hits hitComponent={TheHit} /> */}
                    {user_list}

                    {!user_list?.length > 0 && ( 

                        <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching someone's name . . .</div>
                    )}
                </div>
            </div>

            {/* Create Message confirmation Modal */}  
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{modalUser ? `${modalUser.first_name} ${modalUser.last_name && modalUser.last_name}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={createNewChat}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleTodo}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

CreateNewMessage.propTypes = {
    createChat: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleUserModal: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    clearUser: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    nav: state.nav,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    createChat,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    toggleUserModal,
    getUserById,
    clearUser, 
})(CreateNewMessage);
