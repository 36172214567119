import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const SidebarProfileWidget = ({
    auth: {
        user,
        loading,
        isAuthenticated
    },
    sidebarMobile
}) => {

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);

    // Listen for window resize
    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width on resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    // --- Get user's full name ---

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- END: Get user's full name ---

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <Fragment>

            <div className='sidebar__profile__container' onClick={() => !sidebarMobile ? setProfileDropdown(!profileDropdown) : window.location.href = '/settings/account'}>
                <div className='sidebar__profile'>
                    <div className="post__avatar">

                        {user && user.img ? (
                            <Avatar src={optimized_profile_img_path} />
                        ) : (
                            <DefaultAvatar name={user_name} />
                        )}
                    </div>
                    {(!isTablet || sidebarMobile) && (
                        <Fragment>
                            <div  className='sidebar__profile__text__container'>
                                <div className='sidebar__profile__text'>
                                    <div style={{maxWidth:'130px', wordBreak:'break-word', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                                        <span>
                                            {user_name}
                                        </span>
                                    </div>
                                    {user && (
                                        <div>
                                            <div style={{maxWidth:'130px', wordBreak:'break-word', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                                                <span>@{user.username}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {!sidebarMobile && (
                                <div className='sidebar__profile__btn'>
                                    <MoreHorizIcon />
                                </div>
                            )}
                            
                        </Fragment>
                    )}
                </div>
            </div>

            {/* Dropdown Menu */}
            <div className={profileDropdown ? "profile-dropdown-sidebar active" : "profile-dropdown-sidebar"}>
                <div className="menu">
                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />

                        <div onClick={() => window.location = `/${user.username}`} className="menu-item">
                            Account Settings
                        </div>

                        <div onClick={() => window.location = '/settings/account'} className="menu-item">
                            Inbox
                        </div>

                        <div onClick={() => window.location = '/settings/account'} className="menu-item">
                            Saved
                        </div>

                        <div onClick={() => window.location = '/logout'} className="menu-item danger">
                            Log Out
                        </div>
                </div>
            </div>

            {/* Hidden Overlay for dropdown */}
            <div
                className={`hidden-overlay ${profileDropdown ? "show" : ""}`}
                onClick={() => setProfileDropdown(!profileDropdown)}
            />
        </Fragment>
    )
}

SidebarProfileWidget.propTypes = {
    // Props for authentication
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the authentication state
    auth: state.auth,
})

export default connect(mapStateToProps, { 
    // Connecting actions to the component
})(SidebarProfileWidget);