import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadChats } from '../../actions/chatActions';
import { getUnreadCount } from '../../actions/notificationActions';
import { goBack } from '../../actions/navActions';

// Components - imported
import Message from '../Message/Message';
import { Button } from '@material-ui/core';
import Spinner from '../common/Spinner';
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import AuthDropdown from '../Dropdowns/AuthDropdown';
import ProfileDropdown from '../Dropdowns/ProfileDropdown';
import Btn_For_Headers from '../common/Btn_For_Headers';
import Avatar_For_Headers from '../common/Avatar_For_Headers';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { toggleUserModal } from '../../actions/navActions';

const Nav_ChatBox = ({ 
    toggleUserModal,
    getUnreadChats,
    getUnreadCount,
    goBack,
    chat, 
    getChatById, 
    sideNav, 
    handleSlideMenu, 
    toggleUsernameModal, 
    auth, 
    nav, 
    notification, 
    history 
}) => {
    
    // Initialize the state for the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge state
    const [badgeValue, setBadgeValue] = useState(0);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, [window.location]);
    
    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        if(sideNav) {
            document.getElementById('feed-header').classList.remove("active");
            return "feed__header";
        } else {
            setTimeout(() => {
                document.getElementById('feed-header').classList.add("active");
            }, 700)
        }
    }, [sideNav])

    useEffect(() => {

        // Fetch notification count and unread chats when user is authenticated
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Set the badge value by summing up the notification count and unread chat count
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
        
    }, [notification.num_unread, chat.num_unread_chats])

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    let chatList;

    // Display a spinner if chats are still loading
    if(auth.isAuthenticated && !auth.loading) {
        if(chat.chats === null || chat.loading) {
            chatList = <Spinner />;
        }
        else {
            if(chat.chats.length > 0) {
                // Render the list of Chat conversations
                chatList = chat.chats.map(chat => {
                    if(chat) {
                        return (
                            <Message
                                key={chat._id}
                                auth={auth}
                                chat={chat}
                                getChatById={getChatById}
                                history={history}
                                text={chat.last_message ? chat.last_message : '...'}
                            />
                        )
                    }
                })
            }
            else {
                // Display a message when there are no chats
                chatList = (
                    <div className="no-chats">
                        <h1>Send a message, get a message</h1>
                        <p>Direct Messages are private conversations between you and other people.</p>
                        {/* {auth.user && (
                            <Button  
                                onClick={toggleUsernameModal} 
                                variant="outlined" 
                                className="noChats__btn"
                            >
                                    Start Conversation
                            </Button>
                        )} */}
                    </div>
                );
            }
        }
    } else {
        // Display a message when there are no chats
        chatList = (
            <div className="no-chats">
                <h1>Send a message, get a message</h1>
                <p>Direct Messages are private conversations between you and other people.</p>
            </div>
        );
    }

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <div className="feed">

            {/* Chat Nav Header */}
            <div className="feed__header" id="feed-header">

                {/* Title */}
                <h2 style={{display: 'flex', alignItems: 'center'}}>

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        {isMobile && (
                            <div onClick={goBack} className="profile__btnContainer">
                                <ArrowBackIcon />
                            </div>
                        )}

                        {/* Render notification badge for mobile devices */}
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge home">
                                {badgeValue}
                            </span>
                        ) : null}
                        
                        Messages
                    </div>

                    <div style={{right:'20px', position:'absolute', display: 'flex'}}>

                        {/* Button for composing a new message */}
                        {auth.user && (
                            <Btn_For_Headers handleClick={toggleUserModal}>
                                <AddIcon />
                            </Btn_For_Headers>
                        )}
                        

                        {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '-5px', marginTop:'-3px', marginBottom: '0px'}}>
                            <NightsStayOutlinedIcon />
                        </div> */}

                        {/* --- Avatar Icon --- */}
                        <Avatar_For_Headers />
                        {/* --- END: Avatar Icon --- */}
                    </div>
                </h2>

                {/* Render the new message button */}
                {/* <div className="closeBtn__container">
                    <button onClick={toggleUsernameModal} className="secondaryBtn">
                        <AddIcon /> 
                        <span>New Message</span>
                    </button>
                </div> */}
            </div>
            
            {/* Render the chatList */}
            {chatList}

            {/* Add space to the bottom of page */}
            <div className="no-rides" />
        </div>
    )
}

Nav_ChatBox.propTypes = {
    // Prop type validation for functionto toggle the user list modal
    toggleUserModal: PropTypes.func.isRequired,
    // Prop type validation for function fetching the un-read Chats
    getUnreadChats: PropTypes.func.isRequired,
    // Prop type validation for function fetching the un-read notifications
    getUnreadCount: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for chat
    chat: PropTypes.object.isRequired,
    // Prop type validation for notifications
    notification: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the chat state
    chat: state.chat,
    // Mapping the notification state
    notification: state.notification
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleUserModal,
    getUnreadChats, 
    getUnreadCount,
    goBack
})(Nav_ChatBox);
