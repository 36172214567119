import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Google Maps API
import { useLoadScript } from '@react-google-maps/api';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';
import { signInWithGoogle } from '../../actions/authActions';
import { goBack, set_scroll_to_top } from '../../actions/navActions';

// Routing
import { Link, useHistory, useLocation } from 'react-router-dom';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Components - imported
import Widgets from '../Widgets/Widgets';
import Contact_Widget from '../Widgets/Contact_Widget';
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Spinner from '../common/Spinner';
import GoogleBtn from '../common/GoogleBtn';
import AuthDropdown from '../Dropdowns/AuthDropdown';
import ProfileDropdown from '../Dropdowns/ProfileDropdown';
import Avatar_For_Headers from '../common/Avatar_For_Headers';
import Search_Input from '../common/Search_Input';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MailIcon from '@material-ui/icons/Mail';

// Imgs
import tunde_logo2 from '../../utils/imgs/tunde_logo2.png';
import google_logo from '../../utils/imgs/google_logo.jpg';
import Verification_Badge from '../common/Verification_Badge';

const Layout = ({ 
    getUnreadCount,
    getUnreadChats,
    children, 
    user, 
    page, 
    auth, 
    signInWithGoogle,
    goBack,
    set_scroll_to_top,
    nav: {
        campus_name,
        scroll_to_top
    }, 
    post: { post }, 
    chat, 
    notification, 
    notificationsPage, 
    sideNav, 
    handleSlideMenu, 
    totalPosts,
    handleScroll,
}) => {
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);
    
    const [badgeValue, setBadgeValue] = useState(0);

    // Retrieve the current location using the "useLocation" hook from React Router
    let location = useLocation()

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("filter");

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

    const scrollingElementRef = useRef(null);

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        if(scroll_to_top) {
            scrollToTop();
        }
    }, [scroll_to_top]);

    

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Apply CSS classes to the feed header based on the value of "sideNav" and "isMobile"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }

        if(!isTablet) {
            if(profileDropdown) setProfileDropdown(false);
        }
    }, [sideNav, isMobile, isTablet])

    // Fetch unread notification count and unread chat count when the authenticated user changes
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();

            if(authDropdown) setAuthDropdown(false);
        } else {
            if(profileDropdown) setProfileDropdown(false);
        }

    }, [auth.user]);

    // Update the badge value based on the counts of unread notifications and unread chats
    useEffect(() => {
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
    }, [notification.num_unread, chat.num_unread_chats])

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    // --- Campus Logis ----

    let app_logo = tunde_logo2;

    // --- END: Campus Logis ----

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    const scrollToTop = () => {
        if (scrollingElementRef.current) {
          scrollingElementRef.current.scrollTop = 0;
        }

        set_scroll_to_top(false);
    };

    // -- Dropdown Menu --

    const handleGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(async (result) => {
            console.log('RETURNED USER')
            console.log(result.user);
            console.log(result.user.email);

            const {
                displayName,
                email,
                phoneNumber,
                photoURL,
                emailVerified
            } = result.user;

            signInWithGoogle({
                displayName,
                email,
                phoneNumber,
                photoURL,
                emailVerified
            })
        })
    }

    // -- END: Dropdown Menu

    // -- Campus Buy Sell Website --

    const handle_Campus_Redirect = () => {
  
        if(auth.user.campus_id === 'b7nqQGbbbzw08uh0MoPA') { // University of Oklahoma
            window.open('https://www.oubuysell.com', '_blank');
        } 
        else if (auth.user.campus_id === 'j4R70faL7jaXTR5Nzeex') { // Univeristy of Texas
            window.open('https://www.utbuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'fNie9ly0jB0cs3tZHQaQ') { // Southern Methodist University (SMU)
            window.open('https://www.smubuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'g4O5N3JOeQPiMxoSbCex') { // Univeristy of Florida (UF)
            window.open('https://www.ufbuysell.com', '_blank');
        }
    }

    // -- END: Campus Buy Sell Website --


    return (
        <Fragment>

            <div className="feed__container">
                <div onScroll={handleScroll} className="feed" ref={scrollingElementRef}>
                    
                    {/**Header */}
                    <div className="feed__header" id="feed-header">
                            
                        {/*
                            Render the header content based on the current page and filter
                            Display a menu icon for mobile devices and handle slide menu if clicked
                            Display a badge with the badgeValue if it's greater than 0 and it's a mobile device
                        */}

                        {notificationsPage && (
                            <Fragment>
                                <div style={{ marginRight: '20px', marginLeft: '20px', marginBottom: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: '1'}}>
                                    {/* Header with menu icon and header text */}
                                    <h2 style={{display:'flex', alignItems: 'center', fontSize:'20px'}}>
                                        
                                        {isMobile && (
                                            <Fragment>
                                                <div onClick={goBack} className="profile__btnContainer">
                                                        <ArrowBackIcon />
                                                </div>

                                                {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                                {badgeValue > 0 && (
                                                    <span className="feed-header-badge home">
                                                        {badgeValue}
                                                    </span>
                                                )}
                                            </Fragment>
                                            
                                        )}

                                        {/* Header text */}
                                        Notifications

                                    </h2>
                                    
                                    <div style={{position:'relative', display: 'flex'}}>
                                        {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                            <NightsStayOutlinedIcon />
                                        </div> */}
                                    
                                        {/* --- Avatar Icon --- */}
                                        <Avatar_For_Headers />
                                        {/* --- END: Avatar Icon --- */}
                                    </div>
                                    
                                </div>
                                
                            </Fragment>
                        )}

                        {page === 'saved' && (
                            <Fragment>

                                {/* Header with menu icon and header text */}
                                <div style={{display:'flex', alignItems:'center'}} className="profile__headerText likePage">
                                    
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        {isMobile && (
                                            <div onClick={goBack} style={{marginRight: '10px'}} className="shopFeed__backBtn noMargin noPadding">
                                                <div>
                                                    <ArrowBackIcon />
                                                </div>
                                            </div>
                                        )}

                                        {/* Title and username */}
                                        <div>
                                            <h3 style={{fontWeight: 800}}>
                                                Saved
                                            </h3>
                                            {/* <p style={{fontSize: '13px', color: 'rgb(83, 100, 113)'}}> @{auth.user.username}</p> */}
                                        </div>

                                        {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                        {isMobile && badgeValue > 0 ? (
                                            <span className="feed-header-badge home">
                                                {badgeValue}
                                            </span>
                                        ) : null}
                                    </div>

                                    <div style={{flex: 1}} />

                                    <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>

                                        {/* <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                            <SearchIcon />
                                        </div> */}

                                        {/* --- Avatar Icon --- */}
                                        <Avatar_For_Headers />
                                        {/* --- END: Avatar Icon --- */}
                                    </div>
                                        
                                </div>

                            </Fragment>
                        )}

                        {page === 'profile' && (
                            <div className="layout__profileHeader">


                                {isMobile && (
                                    <Fragment>
                                        <div onClick={isTablet ? handleSlideMenu : undefined} className="shopFeed__backBtn">
                                            <MenuIcon />
                                        </div>
                                    </Fragment>
                                )}

                                {/* Display the header text based on the filter value */}
                        

                                {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                {isMobile && badgeValue > 0 ? (
                                    <span className="feed-header-badge home">
                                        {badgeValue}
                                    </span>
                                ) : null} 

                                <div style={{width: '10px'}}></div>

                                {/* Header Content */}
                                <div className="profile__headerText">
                                    <h3 style={{display:'flex'}}>
                                        <div> 
                                            <img className="sidebar__twitterIcon" src={app_logo} style={{maxHeight: '24px', marginLeft: '0px', marginBottom: '0px'}} alt="logo" />
                                        </div>
                                
                                    </h3>
                                </div>

                                <div style={{flex: 1}} />

                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>

                            </div>
                        )}

                        {page === 'profile edit' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge defaultBadge">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </div>

                                {/* Title for the Edit Profile page */}
                                <div className="profile__headerText">
                                    <h3>Edit Profile</h3>
                                </div>

                                <div style={{flex: 1}} />

                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                        <SearchIcon />
                                    </div> */}

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                                    
                            </div>
                        )}

                        {page === 'post' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge defaultBadge">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="profile__headerText">

                                    {/* Title for a specific post */}
                                    <h3>
                                        {post ? `${post.user.first_name}'s post` : 'Post'}
                                    </h3>
                                </div>

                                <div style={{flex: 1}} />

                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                        <SearchIcon />
                                    </div> */}

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                                    
                            </div>
                        )}

                        {page === 'media post' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={() => window.location = '/media'} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge defaultBadge">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </div>

                                {/* Title for the Liked by page */}
                                <div className="profile__headerText">
                                    <h3>Media</h3>
                                </div>

                                <div style={{flex: 1}} />
                                
                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                        <SearchIcon />
                                    </div> */}

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                                    
                            </div>
                        )}

                        {page === 'liked by' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge defaultBadge">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </div>

                                {/* Title for the Liked by page */}
                                <div className="profile__headerText">
                                    <h3>Liked by</h3>
                                </div>

                                <div style={{flex: 1}} />
                                
                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                        <SearchIcon />
                                    </div> */}

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                                    
                            </div>
                        )}
                        
                    </div>

                    {/* Display Layout content (posts, notifications, etc.) */}
                    {children}
                </div>

            </div>
        </Fragment>
    )
}

Layout.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for notification
    notification: PropTypes.object.isRequired,
    // Prop type validation for chat
    chat: PropTypes.object.isRequired,
    // Prop type validation for post
    post: PropTypes.object.isRequired,
    // Prop type validation for function to get unread notifications
    getUnreadCount: PropTypes.func.isRequired,
    // Prop type validation for function to get unread chats
    getUnreadChats: PropTypes.func.isRequired,
    signInWithGoogle: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    set_scroll_to_top: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the AUTHENTICATION state
    auth: state.auth,
    // Mapping the NAVIGATION state
    nav: state.nav,
    // Mapping the NOTIFICATION state
    notification: state.notification,
    // Mapping the CHAT state
    chat: state.chat,
    // Mapping the POST state
    post: state.post,
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    getUnreadCount, 
    getUnreadChats,
    signInWithGoogle,
    goBack,
    set_scroll_to_top
})(Layout);
