import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { registerWithEmailAndPassword, parseDisplayName } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Components 

// Icons
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import tunde_logo from '../../utils/imgs/tunde_logo.png';

// Banned Email list
import { bannedEmails } from '../../utils/banned_emails/bannedEmails';

// -- Security Logic ---

const GEO_LOCATION_URL = `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_GEOLOCATION_apiKey}&ip=`;

// -- END: Security Logic --

// Initial State 
const initialState = {
    name: '',
    email: '',
    password: ''
};

const Register_body = ({
    auth: { 
        isAuthenticated,
        user,
        account_loading
    }, 
    nav: {
        campus_id,
        campus_name,
        campus_ext
    },
    registerWithEmailAndPassword,
    parseDisplayName,
    setAlert,
    history 
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // States for form data
    const [formData, setFormData] = useState(initialState);
    const [termsOfService, setTermsOfService] = useState(false);

    // State to disable submit button if required fields are empty
    const [disableBtn, setDisableBtn] = useState(true);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const url_Email = url.searchParams.get("email");

    const { 
        name,
        email,
        password
     } = formData;

     useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);
    
    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    useEffect(() => {

        // Disable the submit button if name, email, password, or termsOfService is empty
        if (name === '' || email === '' || password === '' || !termsOfService) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
        
    }, [name, email, password, termsOfService]);

    useEffect(() => {
        if(url_Email) {
            console.log('URL EMAIL EXISTS')
            console.log(url_Email)
            setFormData({ ...formData, ['email']: url_Email});
        } 

    }, [url_Email]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Validate the input against a regular expression pattern for email
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    const handleAlertToggle = async (e) => {
        try {
            e.preventDefault();

            // -- Security Check -- 

            // Get IP address
            const response = await fetch('https://api.ipify.org');
            const ipAddress = await response.text();

            const ip_data = await fetch(`${GEO_LOCATION_URL + ipAddress}`);
            const ip_data_obj = await ip_data.json();

            if(ip_data_obj.time_zone) {
        
                // -- Detect if using VPN --
                const real = getRealLocation()
                const simulated = ip_data_obj.time_zone.name;
        
                ip_data_obj.timezones_differ = real !== simulated;
                // -- END: Detect if using VPN --
            }

            if( ip_data_obj?.country_code2 ) {
            
                if(ip_data_obj.country_code2 !== 'US' || ip_data_obj.timezones_differ) {
                    window.location.href = '/404';
                    return;
                }
            }

            // -- END: Security Check --
            
            const nameArray = name.split(' ');

            let last_name = '';

            last_name = name.split(' ').slice(-1).join(' ');

            // ADD IF EMAIL IS BANNED SETALERT('You're Banned');
            
            // Perform validations for name and email
            if(nameArray.length > 1 && last_name.length > 1) {
                if(name === '') {
                    setAlert('Name is required.', 'danger');
                } else if (email === '') {
                    setAlert('Email is required.', 'danger');
                } else if (!validateEmail(email)) {
                    setAlert('Invalid email address!', 'danger');
                } else if (bannedEmails.includes(email)) {
                    setAlert('Account has been banned', 'danger');
                } else if (password === '') {
                    setAlert('Password is required.', 'danger');
                } else {
                    // Register
                    console.log('START REGISTER');
                    handleRegister(e);
                }
            } else {
                setAlert('Full name is required', 'danger');
            }
        } catch (error) {
            console.error('CODE 511', error)
            
            // Register
            console.log('START REGISTER');
            handleRegister(e);
        }
    }

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const handleRegister = (e) => {
        e.preventDefault();

        console.log('PARSING DISPLAY NAME')
        console.log(parseDisplayName(name))

        let {
            first_name,
            last_name,
            middle_name
        } = parseDisplayName(name);

        // console.log('first_name', first_name);
        // console.log('last_name', last_name);
        // console.log('middle_name', middle_name);

        // Register the user
        registerWithEmailAndPassword(email, password, first_name, last_name, campus_id, campus_ext);

        // Redirect to login link page with the provided data
        // window.location.href = `/login/sent?email=${email}&first=${first_name}&last=${last_name}`;
    };

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Register Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // --- Site Security Functions ---

    function getRealLocation() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    
    // --- END: Site Security Functions ---
   
    return (
        <div className='register_container'>
            <div className='register_content_container'>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo'>
                            <img src={tunde_logo} />
                        </div>

                        {/* Title */}
                        <div className='register_title'>
                            Create your account
                        </div>

                        {/* Header Description */}
                        <div className='register_title secondary'>
                            Set up your profile details to continue
                        </div>
                    </div>

                    {/* Register Form */}
                    <form>
                        <div className='register_form'>

                            {/* Form Title */}
                            <div className='register_form_title'>
                                Your profile information 
                                <hr style={{background:'rgb(196, 199, 204)', height:'1px', flex:'.96 1 0%', boxSizing:'border-box'}} />
                            </div>

                            {/* Form Inputs */}
                            <div className='register_form_inputs'>

                                {/* Email Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Your email
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>

                                {/* Name Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Your name
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="text"
                                        placeholder="e.g., John Doe"
                                        className="input"
                                        name="name"
                                        value={name}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>

                                {/* Password Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Password
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="password"
                                        placeholder="Password"
                                        className="input"
                                        name="password"
                                        value={password}
                                        maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                                <hr style={{background:'rgb(196, 199, 204)', height:'0.5px', flex:'1 1 0%', margin:'0px 32px 0px 16px'}} />
                                
                                {/* Terms of service Checkbox */}
                                <div style={{boxSizing:'border-box', marginTop:'17px'}}>
                                    <div className='register_checkBox_container'>
                                        <input 
                                            style={{cursor:'pointer'}}
                                            type="checkbox" 
                                            name="termsOfService" 
                                            checked={termsOfService}
                                            onChange={() => setTermsOfService(!termsOfService)}
                                        />
                                        <label style={{margin:'0px 0px 0px 10px'}}>I agree not to do anything crazy</label>
                                    </div>
                                </div>

                                <div style={{height: '10px'}}></div>

                                <div className='register_redirect flex-start'>
                                    <div>
                                        <div>
                                            Already have an account?
                                        </div>
                                        <div style={{marginLeft: '5px'}}>

                                            <a href='/login' style={{color:'#3385ff', fontWeight:'bold', cursor: 'pointer'}}>
                                                Log In
                                            </a>
                                        </div>
                                    </div>
                            
                                </div>
                            </div>
                        </div>

                        {/* Form Actions */}
                        <div className='register_btns'>
                            <div 
                                style={{cursor:'pointer', outline:'0px', border:'1px solid rgb(220, 222, 224)', borderRadius:'100px', letterSpacing:'-0.4px', fontWeight:'500', background:'#fff', color:'rgb(49, 50, 51)', lineHeight:'17px', padding:'14px 24px', marginTop:'24px', marginRight:'16px', height:'60px', width:'120px', fontSize:'14px', display:'flex', alignItems:'center', justifyContent:'center', textDecoration:'none', boxSizing:'border-box'}}
                                onClick={() => history.goBack()}
                            >
                                {/* <ArrowBackIcon /> */}
                                Go Back
                            </div>

                            {!account_loading ? (
                                <button 
                                    disabled={disableBtn}
                                    onClick={handleAlertToggle} 
                                    className={`register_btn ${!disableBtn ? 'active' : '' }`}
                                >
                                    Create account
                                </button>
                            ) : (
                                <button 
                                    disabled={true}
                                    className={`register_btn active`}
                                >
                                    Creating...
                                </button>
                            )}

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

Register_body.propTypes = {
    registerWithEmailAndPassword: PropTypes.func.isRequired,
    parseDisplayName: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    registerWithEmailAndPassword,
    parseDisplayName,
    setAlert
})(Register_body);
